import React, {
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Offcanvas,
  Row,
  Spinner
} from 'react-bootstrap';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import SchoolsListHeader from './SchoolsListHeader';
import { useBreakpoints } from 'hooks/useBreakpoints';
import SchoolsListFilters from './SchoolsListFilters';
import { SchoolService } from 'services/SchoolService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import NewSchoolModal from './NewSchoolModal';
import AppContext, { AuthContext, SchoolsContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import { faGraduationCap, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { searchData } from 'helpers/utils';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';

const getStatusColor = status => {
  switch (status) {
    case 'active':
      return 'success';
    case 'suspended':
      return 'danger';
    default:
      return 'danger';
  }
};

const SchoolsList = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const { t } = useTranslation();
  const { user, isAdmin } = useContext(AuthContext);
  const { schools, refreshSchools } = useContext(SchoolsContext);
  const navigate = useNavigate();
  const { breakpoints } = useBreakpoints();

  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [bulkLoading, setBulkLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const [newSchoolModalOpen, setNewSchoolModalOpen] = useState(false);
  const [schoolToEdit, setSchoolToEdit] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /**
   * useCallback version of "getData" to filter and transform `schools`.
   * Declaring [schools] in the dependency array means this function
   * is re-created whenever `schools` changes.
   */
  const getData = useCallback(
    currentFilters => {
      let filteredData = [...schools];

      // 1) Filter by status
      if (currentFilters.status) {
        filteredData = filteredData.filter(
          school => school.status === currentFilters.status
        );
      }

      // 2) Filter by search
      if (currentFilters.search) {
        filteredData = searchData(
          filteredData,
          currentFilters.search,
          ['name', 'description']
        );
      }

      // 3) Transform data (add unique IDs, subRows, etc.)
      const transformedData = filteredData.map(school => ({
        ...school,
        id: school.id,
        subRows:
          school?.grades?.map(grade => ({
            ...grade,
            id: `grade-${grade.id}`,
            subRows:
              grade?.sections?.map(section => ({
                ...section,
                id: `section-${section.id}`
              })) || []
          })) || []
      }));

      setData(transformedData);
      setLoading(false);
    },
    [schools]
  );

  /**
   * Call `getData(filters)` whenever `filters` changes
   * or `schools` triggers a new `getData` reference.
   */
  useEffect(() => {
    getData(filters);
  }, [filters, getData]);

  // If we set a school to edit, open the modal
  useEffect(() => {
    if (schoolToEdit) {
      setNewSchoolModalOpen(true);
    }
  }, [schoolToEdit]);

  // Define table columns
  const columns = [
    {
      accessor: 'name',
      Header: t('schools:table.name'),
      Cell: ({ row }) => {
        const { name } = row.original;
        return (
          <div className="d-flex align-items-center justify-content-start gap-2">
            <Avatar
              src={`https://mathmania.app/static/img/SchoolLogos/${name}.png` ?? avatar}
            />
            {name}
          </div>
        );
      }
    },
    {
      accessor: 'description',
      Header: t('schools:table.description')
    },
    {
      accessor: 'status',
      Header: t('schools:table.status'),
      Cell: ({ row }) => {
        const { status } = row.original;
        return (
          <Badge bg={getStatusColor(status)}>
            {t(`schools:status.${status}`)}
          </Badge>
        );
      }
    },
    {
      accessor: 'actions',
      Header: t('schools:table.actions'),
      Cell: ({ row }) => {
        const rowData = row.original;
        return (
          <>
            {/* Edit button */}
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => setSchoolToEdit(rowData)}
            >
              {t('common:edit')}
            </IconButton>

            {/* Delete button */}
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faTrash}
              transform="shrink-3"
              onClick={() => handleClickDelete(rowData)}
            >
              {t('common:delete')}
            </IconButton>

            {/* Grades button */}
            <IconButton
              className="me-2 mb-1"
              variant="falcon-default"
              size="sm"
              icon={faGraduationCap}
              transform="shrink-3"
              onClick={() => navigate(`/schools/${rowData.id}/grades`)}
            >
              {t('schools:grades')}
            </IconButton>
          </>
        );
      }
    }
  ];

  // Bulk action (delete multiple items)
  const handleBulkActionClick = ids => {
    setConfirmModalProps({
      open: true,
      callback: handleBulkAction,
      payload: ids,
      text: t('schools:message.deleteText'),
      title: t('schools:message.deleteTitle')
    });
  };

  const handleBulkAction = async ids => {
    const indexes = Object.keys(ids).map(key => key);
    const schoolsData = [];
    data.forEach((school, idx) => {
      if (indexes.includes(`${idx}`)) {
        schoolsData.push(school);
      }
    });

    let successCompletedSchools = [];
    let failedCompletedSchools = [];

    setActionLoading(true);
    setBulkLoading(true);

    try {
      for (const school of schoolsData) {
        try {
          await SchoolService.delete(school.id);
          successCompletedSchools.push(school.id);
        } catch (error) {
          failedCompletedSchools.push(school.id);
          console.error('Failed to delete school: ', error);
        }
      }

      if (successCompletedSchools.length) {
        toast.success(
          t('schools:message.successBulkDelete', {
            count: successCompletedSchools.length
          })
        );
      }
      if (failedCompletedSchools.length) {
        toast.error(
          t('schools:message.failedBulkDelete', {
            count: failedCompletedSchools.length
          })
        );
      }

      if (refreshSchools) refreshSchools();

      setBulkLoading(false);
      setActionLoading(false);
      setConfirmModalProps(null);
    } catch (error) {
      setBulkLoading(false);
      setActionLoading(false);
      console.error('Failed to complete bulk action ', error);
      toast.error('Failed to complete bulk action');
    }
  };

  // Single-delete flow
  const handleClickDelete = schoolData => {
    setConfirmModalProps({
      open: true,
      callback: payload => {
        setActionLoading(true);
        SchoolService.delete(payload.id)
          .then(() => {
            if (refreshSchools) refreshSchools();
            setConfirmModalProps(null);
            toast.success(t('schools:message.schoolDeleteSuccess'));
            setActionLoading(false);
          })
          .catch(error => {
            setActionLoading(false);
            console.error(`Error deleting school with id ${payload.id}`, error);
            toast.error('An error has occurred when trying to delete school', {
              autoClose: false
            });
          });
      },
      payload: { id: schoolData.id },
      text: t('schools:message.deleteText'),
      title: t('schools:message.deleteTitle')
    });
  };

  // Confirm-Modal handler
  const handleModalConfirm = () => {
    if (confirmModalProps?.callback) {
      confirmModalProps.callback(confirmModalProps.payload);
    }
  };

  // Show a spinner while data is fetched/filtered
  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner variant="primary" animation="grow" />
      </div>
    );
  }

  return (
    <Row className="gx-3">
      {/* Main Table Section */}
      <Col xxl={10} xl={9}>
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          pagination
          perPage={5}
          selection
          selectionColumnWidth={30}
          keyColumn="id"
        >
          <Card>
            <Card.Header className="px-0">
              <SchoolsListHeader
                table
                searchValue={filters.search || ''}
                setSearchValue={value => setFilters({ ...filters, search: value })}
                handleShow={handleShow}
                handleNewSchoolClick={() => setNewSchoolModalOpen(true)}
                onBulkActionConfirm={handleBulkActionClick}
              />
            </Card.Header>

            <Card.Body className="p-0">
              {/* The actual table */}
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3 px-2">
                <AdvanceTableFooter
                  rowCount={data.length || 0}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </Card.Body>

            <Card.Footer>
              <AdvanceTablePagination table />
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      </Col>

      {/* Filters Section */}
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">{t('common:filter')}</h6>
            </Offcanvas.Header>
            <SchoolsListFilters
              onChange={(name, value) => setFilters({ ...filters, [name]: value })}
              filters={filters}
              handleClear={() => setFilters({})}
            />
          </Offcanvas>
        ) : (
          <SchoolsListFilters
            onChange={(name, value) => setFilters({ ...filters, [name]: value })}
            filters={filters}
            handleClear={() => setFilters({})}
          />
        )}
      </Col>

      {/* Confirmation Modal */}
      <Modal
        show={confirmModalProps?.open}
        onHide={() => setConfirmModalProps(null)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{confirmModalProps?.title || ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{confirmModalProps?.text || ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmModalProps(null)}
            disabled={actionLoading}
          >
            {t('common:cancel')}
          </Button>
          <Button onClick={handleModalConfirm} disabled={actionLoading}>
            {actionLoading && <Spinner size="sm" />}
            {!actionLoading && t('common:confirm')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* New/Edit School Modal */}
      <NewSchoolModal
        open={newSchoolModalOpen}
        setOpen={val => {
          setNewSchoolModalOpen(val);
          // If we close the modal, ensure to clear the "edit" data
          if (!val) setSchoolToEdit(null);
        }}
        refresh={refreshSchools}
        school={schoolToEdit}
      />
    </Row>
  );
};

export default SchoolsList;
